import { signOut } from "firebase/auth"
import { ref as dbRef, set, update } from "firebase/database"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage"
import { createRef, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Select from "react-select"
import { v4 } from "uuid"
//icons
import boldImg from "../../assets/icons/bold52.png"
import italicImg from "../../assets/icons/italic52.png"
import underlineImg from "../../assets/icons/underline52.png"
import linkImg from "../../assets/icons/link50.png"
import photoImg from "../../assets/icons/photo.svg"
import videoImg from "../../assets/icons/video.svg"
import { auth, db, storage } from "../../config/firebase"
import { logout } from "../../features/userSlice"
import "./AdminPanel.css"

import { categoryOption } from "./category/Category"
import success from "../../assets/gif/success.gif"
import failed from "../../assets/gif/failed.webp"
import StorageImages from "../../Components/StorageImages/StorageImages"
import ModalWindow from "../../Components/ModalWindow/ModalWindow"
import StorageVideo from "../../Components/StorageVideo/StorageVideo"
import { removeLocation } from "../../features/locationSlice"
import Map from "../../Components/Map/Map"
import { returnValueFromArrays } from "../../helpers/planeJs"
import { useQueryClient } from "react-query"
import {
  addLocationToIndexedDB,
  updateLocationInIndexedDB,
} from "../../utils/indexedDB"
import {
  Editor,
  EditorState,
  convertToRaw,
  RichUtils,
  Modifier,
  CompositeDecorator,
  SelectionState,
  ContentState,
} from "draft-js"
import { stateFromHTML } from "draft-js-import-html"
import { stateToHTML } from "draft-js-export-html"
import "draft-js/dist/Draft.css"
import ImprovisedButton from "./ImrpovisedButton/ImproButton"

//next 3 functions are for handling links
// Function to find link entities
const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    )
  }, callback)
}

// Component to render links
const Hyperlink = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  const handleClick = (e) => {
    e.preventDefault()
    window.open(url, "_blank")
  }

  return (
    <a
      href={url}
      onClick={handleClick}
      style={{ color: "#3b5998", textDecoration: "underline" }}
    >
      {props.children}
    </a>
  )
}

// hyperlink decorator
const createLinkDecorator = () => {
  return new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Hyperlink,
    },
  ])
}
//DOBRO DODAJE SPACE, DOBRO CITA STARE!!!!!!!
// function convertFromHTML(htmlString) {
//   // First decode encoded characters that may appear in JSON format
//   let decodedHtml = decodeURIComponent(
//     htmlString.replace(/\\n/g, "\n").replace(/\\"/g, '"')
//   );

//   let parsedText;

//   // Use a more targeted regex to ignore simple anchor tags when checking for HTML content
//   if (/<\/?(?!a\b)[a-z][\s\S]*>/i.test(decodedHtml)) {
//     parsedText = decodedHtml;
//   } else {
//     // Convert newlines into paragraph tags, even if there are simple anchor tags
//     parsedText = decodedHtml
//       .split("\n")
//       .map((line) => (line.trim() ? `<p>${line}</p>` : "<p>&nbsp;</p>")) // Ensure empty paragraphs are visible
//       .join("");
//   }

//   // Correct links and ensure proper HTML encoding
//   parsedText = parsedText
//     .replace(/&quot;/g, '"')
//     .replace(/href="\s*https:\/\/mts.rs/g, 'href="https://mts.rs');

//   if (!parsedText.trim()) {
//     return EditorState.createEmpty(createLinkDecorator()); // Return empty state if no content
//   }

//   try {
//     const contentState = stateFromHTML(parsedText);
//     return EditorState.createWithContent(contentState, createLinkDecorator());
//   } catch (e) {
//     console.error("Greška pri konverziji HTML-a u ContentState:", e);
//     const contentState = ContentState.createFromText(parsedText);
//     return EditorState.createWithContent(contentState, createLinkDecorator());
//   }
// }

//PROBA URI ERROR!
function convertFromHTML(htmlString) {
  let decodedHtml = ""

  try {
    decodedHtml = decodeURIComponent(
      htmlString.replace(/\\n/g, "\n").replace(/\\"/g, '"')
    )
  } catch (e) {
    console.error("Error decoding HTML:", e, htmlString)
    decodedHtml = htmlString // Fallback to using the raw string
  }

  let parsedText = ""

  try {
    if (/<\/?(?!a\b)[a-z][\s\S]*>/i.test(decodedHtml)) {
      parsedText = decodedHtml
    } else {
      parsedText = decodedHtml
        .split("\n")
        .map((line) => (line.trim() ? `<p>${line}</p>` : "<p>&nbsp;</p>"))
        .join("")
    }

    parsedText = parsedText
      .replace(/&quot;/g, '"')
      .replace(/href="\s*https:\/\/mts.rs/g, 'href="https://mts.rs')

    const contentState = stateFromHTML(parsedText)
    return EditorState.createWithContent(contentState, createLinkDecorator())
  } catch (e) {
    console.error("Error converting HTML to ContentState:", e, parsedText)
    return EditorState.createEmpty(createLinkDecorator())
  }
}

// when build of the project is finished you need to manually fix asset-manifest.json (/adminpanel ---delete) and from js file too before hosting!!!
const AdminPanel = () => {
  const [nameEng, setNameEng] = useState(() => "")
  const [nameLat, setNameLat] = useState(() => "")
  const [nameCir, setNameCir] = useState(() => "")

  const [isAccessible, setIsAccessible] = useState(false) // New state for accessibility

  const [photoInputs, setPhotoInputs] = useState(() => [
    { id: v4(), ref: createRef() },
  ])
  const [videoInput, setVideoInput] = useState([{ id: v4(), ref: createRef() }])

  const [descriptionEng, setDescriptionEng] = useState(() =>
    EditorState.createEmpty(createLinkDecorator())
  )
  const [descriptionLat, setDescriptionLat] = useState(() =>
    EditorState.createEmpty(createLinkDecorator())
  )
  const [descriptionCir, setDescriptionCir] = useState(() =>
    EditorState.createEmpty(createLinkDecorator())
  )
  const editorEngRef = useRef(null)
  const editorLatRef = useRef(null)
  const editorCirRef = useRef(null)

  const [activeStylesEng, setActiveStylesEng] = useState({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
  })

  const [activeStylesLat, setActiveStylesLat] = useState({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
  })

  const [activeStylesCir, setActiveStylesCir] = useState({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
  })
  const [currentEditor, setCurrentEditor] = useState("")

  const [coordinateX, setCoordinateX] = useState(() => "")
  const [coordinateY, setCoordinateY] = useState(() => "")

  const [category, setCategory] = useState([])
  const [subcategory, setSubcategory] = useState([])
  const [primaryCategory, setPrimaryCategory] = useState("")
  //-----------------------------------------------all input states
  const [deletingInputStatus, setDeletingInputStatus] = useState(false) //because input automaticaly adds after uploading file and if we dont have this state, we can't delete the last empty input

  const [storagePhotos, setStoragePhotos] = useState([])
  const [storageVideo, setStorageVideo] = useState(null)

  const [deletePhoto, setDeletePhoto] = useState(null)
  const [deleteVideo, setDeleteVideo] = useState(null)

  const [isUploading, setIsUploading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFailed, setIsFailed] = useState(false)

  // const [uploadedVideo, setUploadedVideo] = useState(null)

  const [error, setError] = useState(false)
  const errorMsg = useRef(null)

  const currLocation = useSelector((state) => state.location.location) //redux global state
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const previousLocations = queryClient.getQueryData("locations") || []

  const downloadImages = (imagePaths) => {
    //if we editing location that allready exists
    try {
      imagePaths.map(async (path) => {
        const imageRef = ref(storage, path)
        const imageUrl = await getDownloadURL(imageRef)
        setStoragePhotos((prevState) => [...prevState, { imageUrl, path }])
        setStoragePhotos((prevState) => {
          const uniqueSet = new Set(prevState.map(JSON.stringify))
          const uniqueArray = Array.from(uniqueSet, JSON.parse)
          return uniqueArray
        })
      })
    } catch (error) {
      console.log(error)
    }
  }
  const downloadVideo = async (videoPath) => {
    //if we editing location that allready exists
    try {
      const videoRef = ref(storage, videoPath)
      const videoUrl = await getDownloadURL(videoRef)
      setStorageVideo({ videoUrl, path: videoPath })
    } catch (error) {
      console.log(error)
    }
  }
  const confirmDeletingPhoto = async () => {
    //if we editing location that allready exists
    setIsUploading(true)
    try {
      const photoRef = ref(storage, deletePhoto)
      await deleteObject(photoRef)
      setStoragePhotos((prevState) =>
        prevState.filter((el) => el.path !== deletePhoto)
      )
      const locationRef = dbRef(db, `locations/${currLocation.id}/images`)
      const updatedImagesLocation = storagePhotos
        .filter((image) => image.path !== deletePhoto)
        .map((el) => el.path)
      await set(locationRef, updatedImagesLocation)
    } catch (error) {
      console.log(error)
    } finally {
      setDeletePhoto(null)
      setIsUploading(false)
    }
  }
  const confirmDeletingVideo = async () => {
    //if we editing location that allready exists
    setIsUploading(true)
    try {
      const videoRef = ref(storage, deleteVideo)
      await deleteObject(videoRef)
      setStorageVideo(null)
      const locationRef = dbRef(db, `locations/${currLocation.id}/video`)
      await set(locationRef, "novideo")
    } catch (error) {
      console.log(error)
    } finally {
      setDeleteVideo(null)
      setIsUploading(false)
    }
  }

  useEffect(() => {
    if (currLocation) {
      setNameEng(currLocation.nameEng)
      setNameLat(currLocation.nameLat)
      setNameCir(currLocation.nameCir)
      setDescriptionEng(convertFromHTML(currLocation.descriptionEng))
      setDescriptionLat(convertFromHTML(currLocation.descriptionLat))
      setDescriptionCir(convertFromHTML(currLocation.descriptionCir))
      setCoordinateX(currLocation.lat)
      setCoordinateY(currLocation.lon)
      setIsAccessible(
        currLocation.isAccessible ? currLocation.isAccessible[0] : false
      ) // Set accessibility state

      // check that 'category' is array, if not convert it to one
      const categoryData = Array.isArray(currLocation.category)
        ? currLocation.category
        : [currLocation.category] // even if there is only one category selected

      const categoryOptions = categoryData.map((cat) => ({
        label: cat.charAt(0).toUpperCase() + cat.slice(1).toLowerCase(),
        value: cat.toLowerCase(),
      }))

      setCategory(categoryOptions)
      setPrimaryCategory(currLocation.primaryCategory)

      // Check if 'subcat' is defined before mapping
      const subcatData = currLocation.subcat || []
      const subcatOptions = subcatData.map((sub) => ({
        label: sub.charAt(0).toUpperCase() + sub.slice(1).toLowerCase(),
        value: sub.toLowerCase(),
      }))
      setSubcategory(subcatOptions)

      if (currLocation.images && currLocation.images !== "noimages") {
        downloadImages(currLocation.images)
      }
      if (currLocation.video !== "novideo") {
        downloadVideo(currLocation.video)
      }
    }
  }, [currLocation])

  useEffect(() => {
    //adding new input after uploading file but only if deletingInputStatus is true
    const emptyInput = photoInputs.filter((input) => input.img === undefined)

    if (emptyInput.length === 0 && deletingInputStatus) {
      handleAddInput()
    }
  }, [photoInputs])

  const handleAddInput = () => {
    //adding new photo input
    setDeletingInputStatus(false)
    setPhotoInputs((prevState) => {
      return [...prevState, { id: v4(), ref: createRef() }]
    })
  }
  const handleRemoveInput = (id) => {
    //removing photo input
    if (photoInputs.length === 1) return

    setDeletingInputStatus(false)
    setPhotoInputs((prevState) => prevState.filter((el) => el.id !== id))
  }
  const handleInputPhotoClicked = (id) => {
    //input is hidden and need to be woked like this
    photoInputs.find((el) => el.id === id).ref.current.click()
  }
  const handleInputVideoClicked = () => {
    //input is hidden and need to be woked like this
    if (storageVideo) return

    videoInput[0].ref.current.click()
  }
  const handleInputUploaded = (id) => {
    //making readable photo after uploading to show instantly
    setDeletingInputStatus(true)

    const file = photoInputs.find((el) => el.id === id).ref.current.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const uploadedImage = e.target.result
        setPhotoInputs((prevState) =>
          prevState.map((el) => {
            if (el.id === id) {
              return { ...el, img: uploadedImage }
            } else {
              return el
            }
          })
        )
      }
      reader.readAsDataURL(file)
    }
  }
  const handleInputVideoUploaded = (e) => {
    //making readable video after uploading to show instantly
    const file = e.target.files[0]

    setVideoInput((prevState) => {
      return prevState.map((el) => {
        if (el.ref.current.value) {
          return { ...el, video: URL.createObjectURL(file) }
        } else {
          return el
        }
      })
    })
  }
  const handleRemoveVideoInput = (id) => {
    setVideoInput((prevState) => {
      let arr = [{ id: v4(), ref: createRef() }]
      return arr
    })
  }
  const cleanForm = () => {
    // clean form after uploading is finished
    setNameEng("")
    setNameLat("")
    setNameCir("")
    setPhotoInputs([{ id: v4(), ref: createRef() }])
    setDescriptionEng(EditorState.createEmpty(createLinkDecorator()))
    setDescriptionLat(EditorState.createEmpty(createLinkDecorator()))
    setDescriptionCir(EditorState.createEmpty(createLinkDecorator()))
    setCoordinateX("")
    setCoordinateY("")
    setCategory([])
    setSubcategory([])
    setPrimaryCategory("")
    setVideoInput([{ id: v4(), ref: createRef() }])
    setStorageVideo(null)
    setIsAccessible(false)
    dispatch(removeLocation())
  }

  const gifTimeout = (time, setStatus) => {
    setTimeout(() => {
      setStatus(false)
    }, time)
  }
  //---------------------------------------------------------------------------------

  const handleSubmitLocation = async (e) => {
    //fetching data to firebase
    e.preventDefault()

    errorMsg.current = null
    setError(false)

    // Checks if any category is selected
    if (!category.length) {
      errorMsg.current = "Please select at least one category."
      setError(true)
      return
    }

    // Checks for primary category selection if more than one category is selected
    if (category.length > 1 && !primaryCategory) {
      errorMsg.current =
        "Please select a primary category when multiple categories are chosen."
      setError(true)
      return
    }
    if (
      coordinateX < -90 ||
      coordinateX > 90 ||
      coordinateY < -90 ||
      coordinateY > 90
    ) {
      return
    }

    setIsUploading(true)

    const descriptionEngHTML = convertToHTML(descriptionEng)
    const descriptionLatHTML = convertToHTML(descriptionLat)
    const descriptionCirHTML = convertToHTML(descriptionCir)

    const locationId = v4()

    const categoriesToSave = Array.isArray(category) ? category : [category]

    // //uploading images in storage------------------------------
    const photos = photoInputs.filter((el) => {
      if (el.img) {
        return el.ref.current.files[0]
      }
    })

    let imgRefs = []
    if (photos.length > 0) {
      imgRefs = photos.map((photo, index) => {
        const photoFormat = photo.ref.current.files[0].name.split(".")[1]
        if (photoFormat !== "jpg") {
          errorMsg.current = 'Jedini dozvoljen format slike je ".jpg"'
          setIsUploading(false)
        }
        return ref(
          storage,
          `images/locations/${
            currLocation ? currLocation.id : locationId
          }/${v4()}.${photoFormat}`
        )
      })
      if (errorMsg.current) {
        setError(true)
        return
      }
    }
    //video-----------
    const video = videoInput[0].ref.current.files[0]
    let vidRef = null
    if (video) {
      const videoFormat = video.name.split(".")[1]

      vidRef = ref(
        storage,
        `videos/locations/${
          currLocation ? currLocation.id : locationId
        }/0.${videoFormat}`
      )
      uploadBytes(vidRef, video)
        .then((res) => {
          setIsUploading(false)
          setIsSuccess(true)
          gifTimeout(3500, setIsSuccess)
          cleanForm()
          // console.log(res, "video")
        })
        .catch((err) => {
          setIsFailed(true)
          gifTimeout(3500, setIsFailed)
          console.log(err)
        })
    }
    //video-----------
    if (photos.length > 0) {
      photos.forEach((photo, idx) => {
        uploadBytes(imgRefs[idx], photo.ref.current.files[0])
          .then((res) => {
            // console.log(res, "image")
            if (!video) {
              setIsUploading(false)
              setIsSuccess(true)
              gifTimeout(3500, setIsSuccess)
              cleanForm()
            }
          })
          .catch((err) => {
            setIsFailed(true)
            gifTimeout(3500, setIsFailed)
            console.log(err)
          })
      })
    }
    //uploading images in storage---------------------------------

    // //making new location in database-----------------------------
    if (currLocation) {
      //update
      const locationRef = dbRef(db, `locations/${currLocation.id}`)
      const updatedLocation = {
        id: currLocation.id,
        nameEng,
        nameLat,
        nameCir,
        isAccessible: [isAccessible],
        descriptionEng: descriptionEngHTML,
        descriptionLat: descriptionLatHTML,
        descriptionCir: descriptionCirHTML,
        category: categoriesToSave.map((cat) => cat.value),
        primaryCategory: primaryCategory,
        subcat: subcategory.map((sub) => sub.value),
        lat: parseFloat(coordinateX),
        lon: parseFloat(coordinateY),
        images: returnValueFromArrays(imgRefs, storagePhotos),
        video: vidRef
          ? vidRef.fullPath
          : storageVideo
          ? storageVideo.path
          : "novideo",
      }
      update(locationRef, updatedLocation)
        .then((res) => {
          queryClient.setQueryData("locations", (previousLocations) =>
            previousLocations?.map((loc) =>
              loc.id === currLocation.id ? updatedLocation : loc
            )
          )

          updateLocationInIndexedDB(updatedLocation) //indexedDB
          if (!video && !photos[0]) {
            setIsUploading(false)
            setIsSuccess(true)
            gifTimeout(3500, setIsSuccess)
            cleanForm()
          }
        })
        .catch((error) => {
          console.log(error)
          console.log("neuspesan update")
          errorMsg.current =
            "Update neuspesan usled preduge neaktivnosti. Reloadujte stranicu i pokusajte ponovo."
        })
        .finally(() => {
          setStoragePhotos([])
        })
      return
    }
    const newLocation = {
      //create
      id: locationId,
      nameEng: nameEng,
      nameLat: nameLat,
      nameCir: nameCir,
      isAccessible: [isAccessible],
      descriptionEng: descriptionEngHTML,
      descriptionLat: descriptionLatHTML,
      descriptionCir: descriptionCirHTML,
      category: categoriesToSave.map((cat) => cat.value),
      primaryCategory,
      subcat: subcategory.map((sub) => sub.value),
      lat: parseFloat(coordinateX),
      lon: parseFloat(coordinateY),
      images:
        imgRefs.length > 0
          ? imgRefs.map((ref) => {
              return ref.fullPath
            })
          : null,
      video: vidRef ? vidRef.fullPath : "novideo",
    }
    set(dbRef(db, `locations/${locationId}`), newLocation)
      .then((res) => {
        if (previousLocations.length > 0) {
          queryClient.setQueryData("locations", [
            ...previousLocations,
            newLocation,
          ])
        }
        addLocationToIndexedDB(newLocation) //indexedDB
        if (!video && !photos[0]) {
          setIsUploading(false)
          setIsSuccess(true)
          gifTimeout(3500, setIsSuccess)
          cleanForm()
        }
      })
      .catch((error) => {
        console.log(error)
        console.log("neuspesno pravljenje nove lokacije")
      })
    //making new location in database-----------------------------
  }
  //---------------------------------------------------------------------------------
  const handleLogOut = async (e) => {
    //logout
    e.preventDefault()

    try {
      await signOut(auth)
      sessionStorage.removeItem("user")
      dispatch(logout())
      dispatch(removeLocation())
    } catch (error) {
      console.log(error.message)
    }
  }

  function handlePastedText(text, html, editorState, onChange) {
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()

    if (html) {
      // Convert HTML to Draft.js ContentState while handling line breaks
      const blocksFromHtml = stateFromHTML(html, {
        customBlockFn: (element) => {
          if (element.tagName === "BR") {
            return { type: "unstyled", data: {} }
          }
        },
      })
      const newContentState = Modifier.replaceWithFragment(
        currentContent,
        selection,
        blocksFromHtml.getBlockMap()
      )
      const newState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      )
      onChange(newState)
      return "handled"
    } else {
      // Handle plain text by converting it to ContentState and inserting
      const blocksFromText = ContentState.createFromText(text).getBlockMap()
      const newContentState = Modifier.replaceWithFragment(
        currentContent,
        selection,
        blocksFromText
      )
      const newState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      )
      onChange(newState)
      return "handled"
    }
    // Return 'not-handled' if the pasted content could not be processed
    return "not-handled"
  }

  function convertToHTML(editorState) {
    return stateToHTML(editorState.getCurrentContent())
  }

  const promptForLink = (editorState, setEditorState) => {
    const url = window.prompt("Unesite URL za link:")
    if (!url) return

    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const selection = editorState.getSelection()

    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      selection,
      entityKey
    )

    const linkedEditorState = EditorState.push(
      editorState,
      contentStateWithLink,
      "apply-entity"
    )

    setEditorState(EditorState.forceSelection(linkedEditorState, selection))
  }

  const handleEditorContainerClick = (
    editorRef,
    editorState,
    setEditorState
  ) => {
    if (editorRef.current) {
      editorRef.current.focus()
      const selection = editorState.getSelection()
      if (!selection.getHasFocus()) {
        const currentContent = editorState.getCurrentContent()
        const lastBlock = currentContent.getBlockMap().last()
        const key = lastBlock.getKey()
        const length = lastBlock.getLength()

        const newSelection = SelectionState.createEmpty(key).merge({
          anchorOffset: length,
          focusOffset: length,
        })

        setEditorState(EditorState.forceSelection(editorState, newSelection))
      }
    }
  }

  const toggleStyle = (style, editor) => {
    let editorState, setEditorState, setActiveStyles, editorRef

    if (editor === "eng") {
      editorState = descriptionEng
      setEditorState = setDescriptionEng
      setActiveStyles = setActiveStylesEng
      editorRef = editorEngRef
    } else if (editor === "lat") {
      editorState = descriptionLat
      setEditorState = setDescriptionLat
      setActiveStyles = setActiveStylesLat
      editorRef = editorLatRef
    } else if (editor === "cir") {
      editorState = descriptionCir
      setEditorState = setDescriptionCir
      setActiveStyles = setActiveStylesCir
      editorRef = editorCirRef
    }

    // Focus the editor if it is not already focused
    if (document.activeElement !== editorRef.current) {
      editorRef.current.focus()
    }

    const newEditorState = RichUtils.toggleInlineStyle(editorState, style)
    setEditorState(newEditorState)

    setActiveStyles((prevStyles) => ({
      ...prevStyles,
      [style]: !prevStyles[style],
    }))
  }
  //next two function handle the automatic style selection when the text is selected.
  const applyStyles = (
    description,
    setDescription,
    setActiveStyles,
    activeStyles
  ) => {
    const editorState = description
    const currentStyle = editorState.getCurrentInlineStyle()
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      const updatedActiveStyles = {}
      let stylesChanged = false

      Object.keys(activeStyles).forEach((style) => {
        const isCurrentlyActive = currentStyle.has(style)
        updatedActiveStyles[style] = isCurrentlyActive
        if (isCurrentlyActive !== activeStyles[style]) {
          stylesChanged = true
        }
      })

      if (stylesChanged) {
        setActiveStyles(updatedActiveStyles)
      }
    } else {
      Object.keys(activeStyles).forEach((style) => {
        const isActive = activeStyles[style]
        const isCurrentlyActive = currentStyle.has(style)
        if (isActive !== isCurrentlyActive) {
          setDescription((prevState) =>
            RichUtils.toggleInlineStyle(prevState, style)
          )
        }
      })
    }
  }

  useEffect(() => {
    const applyCurrentStyles = () => {
      applyStyles(
        descriptionEng,
        setDescriptionEng,
        setActiveStylesEng,
        activeStylesEng
      )
      applyStyles(
        descriptionLat,
        setDescriptionLat,
        setActiveStylesLat,
        activeStylesLat
      )
      applyStyles(
        descriptionCir,
        setDescriptionCir,
        setActiveStylesCir,
        activeStylesCir
      )
    }

    // Throttle updates to prevent excessive re-rendering
    const tout = setTimeout(applyCurrentStyles, 0)
    return () => clearTimeout(tout)
  }, [
    activeStylesEng,
    activeStylesLat,
    activeStylesCir,
    descriptionEng,
    descriptionLat,
    descriptionCir,
  ])

  const getSubcategoryOptions = () => {
    const options = category.flatMap(
      (cat) =>
        categoryOption.find((option) => option.value === cat.value)
          ?.subcategoryOption || []
    )
    return [...new Set(options.map((option) => JSON.stringify(option)))].map(
      (jsonStr) => JSON.parse(jsonStr)
    )
  }

  const handleCategoryChange = (selectedOptions) => {
    setCategory(selectedOptions || [])

    // update subs based on categories
    updateSubcategories(selectedOptions)

    updatePrimaryCategory(selectedOptions)
  }

  const updateSubcategories = (selectedOptions) => {
    const validSubcategories = selectedOptions
      .flatMap(
        (cat) =>
          categoryOption.find((option) => option.value === cat.value)
            ?.subcategoryOption || []
      )
      .map((option) => option.value)

    // Filter curren subcat to have only valid ones
    setSubcategory(
      subcategory.filter((sub) => validSubcategories.includes(sub.value))
    )
  }

  const updatePrimaryCategory = (selectedOptions) => {
    if (selectedOptions.length === 1) {
      setPrimaryCategory(selectedOptions[0].value)
    } else {
      const primaryCategoryOption = selectedOptions.find(
        (option) => option.value === primaryCategory
      )
      if (!primaryCategoryOption) {
        // if current primary not in categories, reset to the first available one
        setPrimaryCategory(
          selectedOptions.length > 0 ? selectedOptions[0].value : ""
        )
      }
    }
  }

  const handlePrimaryCategoryChange = (selectedOption) => {
    setPrimaryCategory(selectedOption.value)
  }

  const getPrimaryCategoryOptions = () => {
    return category.map((cat) => ({
      label: cat.label,
      value: cat.value,
    }))
  }

  const handleSubcategoryChange = (selectedOptions) => {
    setSubcategory(selectedOptions || [])
  }

  return (
    <div className="admin-page">
      <div className="titleContainer">
        <p className="link">
          <Link to="/locations">Pretraga Lokacija</Link>
        </p>
        <h1>Admin panel</h1>
        <button className="logout" onClick={handleLogOut}>
          Izloguj se
        </button>
      </div>
      <p className="error-msg">{errorMsg.current}</p>
      <form className="form" onSubmit={handleSubmitLocation}>
        <div className="multilingual">
          <label>Naziv</label>
          <input
            type="text"
            value={nameEng}
            onChange={(e) => setNameEng(e.target.value)}
            required
            placeholder="...na Engleskom"
          />
          <input
            type="text"
            value={nameLat}
            onChange={(e) => setNameLat(e.target.value)}
            required
            placeholder="...na Latinici"
          />
          <input
            type="text"
            value={nameCir}
            onChange={(e) => setNameCir(e.target.value)}
            required
            placeholder="...на Ћирилици"
          />
        </div>
        {storagePhotos.length > 0 && (
          <StorageImages
            storagePhotos={storagePhotos}
            setDeletePhoto={setDeletePhoto}
          />
        )}
        <label>Ubaci slike</label>
        <div className="photoInputs-container">
          {photoInputs.map((input, idx) => (
            <div className="singleInput" key={input.id}>
              <input
                onChange={() => handleInputUploaded(input.id)}
                type="file"
                ref={input.ref}
              />
              <img
                className="thumbnail"
                id={input.id}
                onClick={() => handleInputPhotoClicked(input.id)}
                src={input.img ? input.img : photoImg}
                alt="icon"
              />
              <span
                className="removeBtn"
                onClick={() => handleRemoveInput(input.id)}
              >
                x
              </span>
            </div>
          ))}
          <span className="btn-addInput" onClick={handleAddInput}>
            Dodaj
          </span>
        </div>
        {storageVideo && (
          <StorageVideo
            storageVideo={storageVideo}
            setDeleteVideo={setDeleteVideo}
          />
        )}
        <label>Ubaci snimak</label>
        <div className="videoInput-container">
          {videoInput.map((input) => {
            return (
              <div className="singleInput" key={input.id}>
                <input
                  type="file"
                  ref={input.ref}
                  onChange={handleInputVideoUploaded}
                />
                {!input.video && (
                  <img
                    onClick={handleInputVideoClicked}
                    src={videoImg}
                    alt="icon"
                  />
                )}
                {input.video && (
                  <div className="storagePhotoContainer">
                    <video className="video-preview" controls>
                      <source src={input.video} type="video/mp4"></source>
                    </video>
                    <span
                      className="removeBtn"
                      onClick={() => handleRemoveVideoInput(input.id)}
                    >
                      x
                    </span>
                  </div>
                )}
              </div>
            )
          })}
          {/* <div className="singleInput">
            <input
              type="file"
              ref={videoInput[0].ref}
              onChange={handleInputVideoUploaded}
            />
            {!uploadedVideo && (
              <img
                onClick={handleInputVideoClicked}
                src={videoImg}
                alt="icon"
              />
            )}
            {uploadedVideo && (
              <div className="storagePhotoContainer">
                <video className="video-preview" controls>
                  <source src={uploadedVideo} type="video/mp4"></source>
                </video>
                <span
                  className="removeBtn"
                  onClick={() => {
                    setUploadedVideo(null)
                  }}
                >
                  x
                </span>
              </div>
            )}
          </div> */}
        </div>
        <div className="multilingual">
          <label>Dodaj opis</label>

          {/*ENGLESKI */}
          <div
            className="editor-container"
            onClick={() =>
              handleEditorContainerClick(
                editorEngRef,
                descriptionEng,
                setDescriptionEng,
                setActiveStylesEng
              )
            }
          >
            <Editor
              ref={editorEngRef}
              editorState={descriptionEng}
              onChange={setDescriptionEng}
              onFocus={() => setCurrentEditor("eng")}
              placeholder="...na Engleskom"
              handlePastedText={(text, html) =>
                handlePastedText(text, html, descriptionEng, setDescriptionEng)
              }
            />
            <div className="editor-controls">
              <ImprovisedButton
                toggleFunction={() => toggleStyle("BOLD", "eng")}
                className={`style-button ${
                  activeStylesEng.BOLD ? "active" : ""
                }`}
              >
                <img src={boldImg} alt="Bold" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() => toggleStyle("ITALIC", "eng")}
                className={`style-button ${
                  activeStylesEng.ITALIC ? "active" : ""
                }`}
              >
                <img src={italicImg} alt="Italic" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() => toggleStyle("UNDERLINE", "eng")}
                className={`style-button ${
                  activeStylesEng.UNDERLINE ? "active" : ""
                }`}
              >
                <img src={underlineImg} alt="Underline" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() =>
                  promptForLink(descriptionEng, setDescriptionEng)
                }
                className="style-button link"
              >
                <img src={linkImg} alt="Link" />
              </ImprovisedButton>
            </div>
          </div>
          {/* LATINICA!!!!!!!!! */}
          <div
            className="editor-container"
            onClick={() =>
              handleEditorContainerClick(
                editorLatRef,
                descriptionLat,
                setDescriptionLat,
                setActiveStylesLat
              )
            }
          >
            <Editor
              ref={editorLatRef}
              editorState={descriptionLat}
              onChange={setDescriptionLat}
              onFocus={() => setCurrentEditor("lat")}
              placeholder="...na Latinici"
              handlePastedText={(text, html) =>
                handlePastedText(text, html, descriptionLat, setDescriptionLat)
              }
            />
            <div className="editor-controls">
              <ImprovisedButton
                toggleFunction={() => toggleStyle("BOLD", "lat")}
                className={`style-button ${
                  activeStylesLat.BOLD ? "active" : ""
                }`}
              >
                <img src={boldImg} alt="Bold" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() => toggleStyle("ITALIC", "lat")}
                className={`style-button ${
                  activeStylesLat.ITALIC ? "active" : ""
                }`}
              >
                <img src={italicImg} alt="Italic" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() => toggleStyle("UNDERLINE", "lat")}
                className={`style-button ${
                  activeStylesLat.UNDERLINE ? "active" : ""
                }`}
              >
                <img src={underlineImg} alt="Underline" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() =>
                  promptForLink(descriptionLat, setDescriptionLat)
                }
                className="style-button link"
              >
                <img src={linkImg} alt="Link" />
              </ImprovisedButton>
            </div>
          </div>
          {/* CIRILICA!!!!!!!!! */}
          <div
            className="editor-container"
            onClick={() =>
              handleEditorContainerClick(
                editorCirRef,
                descriptionCir,
                setDescriptionCir,
                setActiveStylesCir
              )
            }
          >
            <Editor
              ref={editorCirRef}
              editorState={descriptionCir}
              onChange={setDescriptionCir}
              onFocus={() => setCurrentEditor("cir")}
              placeholder="...на Ћирилици"
              handlePastedText={(text, html) =>
                handlePastedText(text, html, descriptionCir, setDescriptionCir)
              }
            />
            <div className="editor-controls">
              <ImprovisedButton
                toggleFunction={() => toggleStyle("BOLD", "cir")}
                className={`style-button ${
                  activeStylesCir.BOLD ? "active" : ""
                }`}
              >
                <img src={boldImg} alt="Bold" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() => toggleStyle("ITALIC", "cir")}
                className={`style-button ${
                  activeStylesCir.ITALIC ? "active" : ""
                }`}
              >
                <img src={italicImg} alt="Italic" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() => toggleStyle("UNDERLINE", "cir")}
                className={`style-button ${
                  activeStylesCir.UNDERLINE ? "active" : ""
                }`}
              >
                <img src={underlineImg} alt="Underline" />
              </ImprovisedButton>
              <ImprovisedButton
                toggleFunction={() =>
                  promptForLink(descriptionCir, setDescriptionCir)
                }
                className="style-button link"
              >
                <img src={linkImg} alt="Link" />
              </ImprovisedButton>
            </div>
          </div>
        </div>
        <label>Dodaj koordinate</label>
        <div className="cordinates-container">
          <input
            type="number"
            value={coordinateX}
            onChange={(e) => setCoordinateX(e.target.value)}
            placeholder="...latitude"
            required
          />
          <input
            type="number"
            value={coordinateY}
            onChange={(e) => setCoordinateY(e.target.value)}
            placeholder="...longitude"
            required
          />
        </div>
        <Map
          lat={coordinateX}
          lng={coordinateY}
          setLat={setCoordinateX}
          setLng={setCoordinateY}
        />

        <div className="selections-container">
          <div>
            <label>
              Da li je lokalitet pristupačan osobama sa invaliditetom?
            </label>
            <input
              className="checkboxForAccessibility"
              type="checkbox"
              checked={isAccessible}
              onChange={(e) => setIsAccessible(e.target.checked)}
            />
          </div>
          <label>Izaberi kategorije</label>
          <Select
            isMulti
            options={categoryOption}
            value={category}
            onChange={handleCategoryChange}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            classNamePrefix="select"
            className="custom-select-panel"
          />

          {category.length > 0 && (
            <>
              <label>Izaberi potkategorije</label>
              <Select
                isMulti
                options={getSubcategoryOptions()}
                value={subcategory}
                onChange={handleSubcategoryChange}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                classNamePrefix="select"
                className="custom-select-panel"
              />
            </>
          )}

          {category.length > 1 && (
            <>
              <label>Izaberi primarnu kategoriju</label>
              <Select
                options={getPrimaryCategoryOptions()}
                value={category.find((cat) => cat.value === primaryCategory)}
                onChange={handlePrimaryCategoryChange}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                classNamePrefix="select"
                className="custom-select-panel"
              />
            </>
          )}
        </div>

        {errorMsg.current && <p className="error-msg">{errorMsg.current}</p>}
        <button className="btn-submit" type="submit" disabled={isUploading}>
          Dodaj Lokalitet
        </button>
        {isUploading && (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        )}
        {isSuccess && (
          <div className="spinner-container">
            <img className="successgif" src={success} alt="success" />
          </div>
        )}
        {isFailed && (
          <div className="spinner-container">
            <img className="failedgif" src={failed} alt="failed" />
          </div>
        )}
      </form>
      {deletePhoto && (
        <ModalWindow
          title="Brisanje fotografije"
          setDeleteData={setDeletePhoto}
          confirmDeletingData={confirmDeletingPhoto}
        />
      )}
      {deleteVideo && (
        <ModalWindow
          title="Brisanje snimka"
          setDeleteData={setDeleteVideo}
          confirmDeletingData={confirmDeletingVideo}
        />
      )}
    </div>
  )
}

export default AdminPanel
