import "./StorageVideo.css"

const StorageVideo = ({ storageVideo, setDeleteVideo }) => {
  return (
    <div>
      <h4>Snimak iz baze</h4>
      <div className="storageImagesContainer">
        <div className="storagePhotoContainer">
          <video className="video-preview" controls>
            <source src={storageVideo.videoUrl} type="video/mp4"></source>
          </video>
          <span
            className="removeBtn"
            onClick={() => setDeleteVideo(storageVideo.path)}
          >
            x
          </span>
        </div>
      </div>
    </div>
  )
}

export default StorageVideo
