import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDhYRj0Z4KMt7mzB4Al-A9-2HBCxnmTGVY",
  authDomain: "meet-serbia.firebaseapp.com",
  databaseURL: "https://meet-serbia-default-rtdb.firebaseio.com",
  projectId: "meet-serbia",
  storageBucket: "meet-serbia.appspot.com",
  messagingSenderId: "471635787947",
  appId: "1:471635787947:web:44f94915c00104937bf7ee",
  measurementId: "G-2JN476REL5"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getDatabase(app)
export const storage = getStorage(app)