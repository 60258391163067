import "./LocationPreview.css"
import edit from "../../assets/icons/edit.svg"
import remove from "../../assets/icons/remove.svg"
import { useDispatch } from "react-redux"
import { setLocation } from "../../features/locationSlice"
import { useNavigate } from "react-router-dom"

const LocationPreview = ({ location, setDeleteLocation }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleEditLocation = () => {
    // console.log(location)
    dispatch(setLocation(location))
    navigate("/admin")
  }
  return (
    <div className="locationPreviewContainer">
      <h3>{location.nameLat}</h3>
      <h3>{location.primaryCategory}</h3>
      <div className="btnsContainer">
        <img
          onClick={handleEditLocation}
          className="btn-edit"
          src={edit}
          alt="edit button"
        />
        <img
          className="btn-remove"
          src={remove}
          alt="remove button"
          onClick={() => setDeleteLocation(location)}
        />
      </div>
    </div>
  )
}

export default LocationPreview
