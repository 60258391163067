import "./ModalWindow.css"

const ModalWindow = ({ title, setDeleteData, confirmDeletingData }) => {
  return (
    <div className="invisibleWrapper">
      <div className="modalContainer">
        <h2>{title}</h2>
        <div className="btnsContainer">
          <button onClick={() => setDeleteData(null)}>Odustani</button>
          <button onClick={() => confirmDeletingData()}>Potvrdi</button>
        </div>
      </div>
    </div>
  )
}

export default ModalWindow
