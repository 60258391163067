import { useEffect, useRef } from "react"
import "./Map.css"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY

const Map = ({ lat, lng, setLat, setLng }) => {
  const map = useRef(null)
  const mapContainer = useRef(null)
  const marker = useRef(null)

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [20.4489, 44.3866],
      zoom: 6,
    })

    map.current.on("click", (e) => {
      const lat = e.lngLat.lat
      const lng = e.lngLat.lng

      setLat(lat)
      setLng(lng)
    })
    return () => map.current.remove()
  }, [setLat, setLng])

  useEffect(() => {
    if (lat && lng) {
      if (lat < -90 || lat > 90 || lng < -90 || lng > 90) {
        console.log("desava li se")
        return
      }
      if (marker.current) {
        marker.current.remove()
      }
      marker.current = new mapboxgl.Marker()
        .setLngLat([lng, lat])
        .addTo(map.current)
    }
  }, [lat, lng])
  return <div className="mapContainer" ref={mapContainer}></div>
}

export default Map
