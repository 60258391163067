import "./StorageImages.css"

const StorageImages = ({ storagePhotos, setDeletePhoto }) => {
  const handleSetDeletePhoto = (path) => {
    setDeletePhoto(path)
  }
  return (
    <div>
      <h4>Slike iz baze</h4>
      <div className="storageImagesContainer">
        {storagePhotos.map((photo, idx) => {
          return (
            <div className="storagePhotoContainer" key={idx}>
              <img
                className="thumbnail"
                src={photo.imageUrl}
                alt="small thumbnail"
              />
              <span
                className="removeBtn"
                onClick={() => handleSetDeletePhoto(photo.path)}
              >
                x
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StorageImages
