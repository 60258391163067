//category options
export const categoryOption = [
  {
    value: "priroda",
    label: "Priroda",
    subcategoryOption: [
      { value: "ade", label: "Ade" },
      { value: "vodopadi", label: "Vodopadi" },
      { value: "zaštićena područja", label: "Zaštićena područja" },
      { value: "izletišta i vidikovci", label: "Izletišta i vidikovci" },
      { value: "jezera", label: "Jezera" },
      { value: "klisure", label: "Klisure" },
      { value: "pećine", label: "Pećine" },
      { value: "planine", label: "Planine" },
      { value: "reke", label: "Reke" },
      { value: "izvori", label: "Izvori" },
    ],
  },
  { value: "banje", label: "Banje" },
  { value: "gradovi", label: "Gradovi" },
  {
    value: "građevine",
    label: "Građevine",
    subcategoryOption: [
      { value: "brane i hidroelektrane", label: "Brane i hidroelektrane" },
      { value: "dvorci i vile", label: "Dvorci i vile" },
      { value: "mostovi", label: "Mostovi" },
      { value: "narodno graditeljstvo", label: "Narodno graditeljstvo" },
      // { value: "palate", label: "Palate" },
      { value: "zdanja", label: "Zdanja" },
      { value: "spomen-kuće", label: "Spomen-kuće" },
      { value: "tvrđave", label: "Tvrđave" },
      { value: "tornjevi", label: "Tornjevi" },
      { value: "hramovi", label: "Hramovi" },
      { value: "česme", label: "Česme" },
    ],
  },
  {
    value: "kultura",
    label: "Kultura",
    subcategoryOption: [
      { value: "biblioteke", label: "Biblioteke" },
      { value: "bioskopi", label: "Bioskopi" },
      { value: "galerije", label: "Galerije" },
      { value: "istraživački centri", label: "Istraživački centri" },
      { value: "kulturni centri", label: "Kulturni centri" },
      { value: "muzeji i zbirke", label: "Muzeji i zbirke" },
      { value: "pozorišta", label: "Pozorišta" },
      { value: "spomenici", label: "Spomenici" },
      { value: "susreti", label: "Susreti" },
      { value: "celine", label: "Celine" },
    ],
  },
  {
    value: "zanimljivosti",
    label: "Zanimljivosti",
    subcategoryOption: [
      { value: "akva-parkovi", label: "Akva-parkovi" },
      { value: "gondole", label: "Gondole" },
      { value: "zoo-vrtovi", label: "Zoo-vrtovi" },
      { value: "manifestacije", label: "Manifestacije" },
      { value: "prirodnjački centri", label: "Prirodnjački centri" },
      { value: "regate i krstarenja", label: "Regate i krstarenja" },
      { value: "skele", label: "Skele" },
    ],
  },
  {
    value: "sport, rekreacija",
    label: "Sport, Rekreacija",
    subcategoryOption: [
      { value: "adrenalinski", label: "Adrenalinski" },
      { value: "bazeni", label: "Bazeni" },
      { value: "dvorane", label: "Dvorane" },
      { value: "kupališta", label: "Kupališta" },
      { value: "lovišta", label: "Lovišta" },
      { value: "ribolov", label: "Ribolov" },
      { value: "skijališta", label: "Skijališta" },
      { value: "sportovi na vodi", label: "Sportovi na vodi" },
      { value: "sportski centri", label: "Sportski centri" },
      { value: "stadioni", label: "Stadioni" },
      { value: "staze", label: "Staze" },
      { value: "hipodromi", label: "Hipodromi" },
    ],
  },
  { value: "turist-info centri", label: "Turist-Info Centri" },
  {
    value: "smeštaj",
    label: "Smeštaj",
    subcategoryOption: [
      { value: "domaćinstva i etno-sela", label: "Domaćinstva i etno-sela" },
      { value: "kampovi", label: "Kampovi" },
      {
        value: "planinarski domovi i odmarališta",
        label: "Planinarski domovi i odmarališta",
      },
      { value: "sobe i apartmani", label: "Sobe i apartmani" },
      { value: "hosteli", label: "Hosteli" },
      { value: "hoteli", label: "Hoteli" },
    ],
  },
  {
    value: "ugostiteljstvo",
    label: "Ugostiteljstvo",
    subcategoryOption: [
      { value: "barovi pabovi klubovi", label: "Barovi, pabovi, klubovi" },
      { value: "vinarije", label: "Vinarije" },
      { value: "kafei i poslastičarnice", label: "Kafei i poslastičarnice" },
      {
        value: "restorani (opšti, nacionalni, picerije, kafane)",
        label: "Restorani (Opšti, nacionalni, picerije, kafane)",
      },
      { value: "salaši i etno-kuće", label: "Salaši i etno-kuće" },
    ],
  },
  {
    value: "šoping",
    label: "Šoping",
    subcategoryOption: [
      { value: "pijace", label: "Pijace" },
      { value: "tržni centri", label: "Tržni centri" },
      { value: "šoping zone", label: "Šoping zone" },
    ],
  },
  {
    value: "korisne informacije",
    label: "Korisne informacije",
    subcategoryOption: [
      { value: "apoteke", label: "Apoteke" },
      { value: "banke", label: "Banke" },
      { value: "vizni režim", label: "Vizni režim" },
      { value: "gorivo", label: "Gorivo" },
      { value: "zdravlje", label: "Zdravlje" },
      { value: "menjačnice", label: "Menjačnice" },
      { value: "mobilna telefonija", label: "Mobilna telefonija" },
      { value: "osiguranje", label: "Osiguranje" },
      { value: "parking", label: "Parking" },
      { value: "policija", label: "Policija" },
      { value: "pomoć na putu", label: "Pomoć na putu" },
      { value: "praznici i neradni dani", label: "Praznici i neradni dani" },
      { value: "putovanja", label: "Putovanja" },
      { value: "taksi", label: "Taksi" },
    ],
  },
];
