import { useEffect, useRef, useState } from "react";
import "./Locations.css";
import { auth } from "../../config/firebase";
import LocationPreview from "../../Components/LocationPreview/LocationPreview";
import ModalWindow from "../../Components/ModalWindow/ModalWindow";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeLocation } from "../../features/locationSlice";
import { signOut } from "firebase/auth";
import { logout } from "../../features/userSlice";
import {
  fetchLocations,
  useFetchLocationsData,
  useRemoveLocation,
} from "../../hooks/useLocationData";
import { useQueryClient } from "react-query";
import { clearLocationsInIndexedDB } from "../../utils/indexedDB";

const Locations = () => {
  const { locations, isLoading } = useFetchLocationsData(); //initial locations
  const [queryLocations, setQueryLocations] = useState([]); //filtered locations

  const [searchValue, setSearchValue] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const [deleteLocation, setDeleteLocation] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { mutate: deletingLocation } = useRemoveLocation();
  const msg = useRef(null);
  const queryClient = useQueryClient();
  const [isRefetchingLocations, setIsRefetchingLocations] = useState(false);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (locations) {
  //     setIsFetchedLocation(false)
  //   } else {
  //     setIsFetchedLocation(true)
  //   }
  // }, [locations, setIsFetchedLocation])

  useEffect(() => {
    msg.current = null;
    if (searchValue.trim().length > 1) {
      const filteredLocations = locations.filter((location) => {
        const nameLatMatch = location.nameLat
          .toLowerCase()
          .includes(searchValue.toLowerCase());

        const categoryMatch =
          !searchCategory || location.primaryCategory === searchCategory;
        return nameLatMatch && categoryMatch;
      });
      setQueryLocations(filteredLocations);
    } else {
      setQueryLocations([]);
    }
  }, [searchValue, searchCategory, locations]);

  const confirmDeletingLocation = () => {
    setIsDeleting(true);
    deletingLocation({
      deleteLocation: deleteLocation,
      setDeleteLocation: setDeleteLocation,
      setIsLoading: setIsDeleting,
      queryClient: queryClient,
    });
  };
  const handleLogOut = async (e) => {
    //logout
    e.preventDefault();

    try {
      await signOut(auth);
      sessionStorage.removeItem("user");
      dispatch(logout());
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleRefetchLocations = async () => {
    setIsRefetchingLocations(true);
    try {
      await clearLocationsInIndexedDB();
      await fetchLocations();
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefetchingLocations(false);
    }
  };
  return (
    <div className="locationsWrapper">
      <div className="titleContainer">
        <p className="link">
          <Link to="/admin" onClick={() => dispatch(removeLocation())}>
            Admin panel
          </Link>
        </p>
        <h1>Pretraga lokacija</h1>
        <button className="logout" onClick={handleLogOut}>
          Izloguj se
        </button>
      </div>
      <div>
        <button onClick={handleRefetchLocations} style={{ margin: "0" }}>
          Osvezi lokacije
        </button>
      </div>
      <div className="searchboxWrapper">
        <div>
          <label>
            Pretraga lokaliteta se automatski pokrece nakon unosa dva karaktera
            u polje za pretragu
          </label>
          <input
            type="text"
            placeholder="Unesi naziv lokaliteta..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="selectCategoryContainer">
          <label>Izaberi kategoriju</label>
          <select
            defaultValue="default"
            onChange={(e) => setSearchCategory(e.target.value)}
          >
            <option value="">Sve kategorije</option>
            <option value="priroda">Priroda</option>
            <option value="banje">Banje</option>
            <option value="gradovi">Gradovi</option>
            <option value="građevine">Građevine</option>
            <option value="kultura">Kultura</option>
            <option value="zanimljivosti">Zanimljivosti</option>
            <option value="sport, rekreacija">Sport, Rekreacija</option>
            <option value="turist-info centri">Turist-Info Centri</option>
            <option value="smeštaj">Smeštaj</option>
            <option value="ugostiteljstvo">Ugostiteljstvo</option>
            <option value="šoping">Šoping</option>
            <option value="korisne informacije">Korisne informacije</option>
          </select>
        </div>
      </div>
      {/* {queryLocations?.length === 0 ? (
        <p>{msg.current ? msg.current : "Lokacije..."}</p>
      ) : (
        <div className="locationsContainer">
          {queryLocations?.map((location) => {
            return (
              <LocationPreview
                key={location.id}
                location={location}
                setDeleteLocation={setDeleteLocation}
              />
            )
          })}
        </div>
      )} */}
      {queryLocations?.length === 0 ? (
        <p>{msg.current ? msg.current : "Lokacije..."}</p>
      ) : (
        <div className="locationsContainer">
          {queryLocations?.map((location) => {
            // Koristi primaryCategory za prikaz, ali zadržava sve kategorije
            // const displayCategory = location.primaryCategory
            //  || location.category[0];

            return (
              <LocationPreview
                key={location.id}
                location={{ ...location }} // Prikazuje primaryCategory
                setDeleteLocation={setDeleteLocation}
              />
            );
          })}
        </div>
      )}
      {deleteLocation && (
        <ModalWindow
          title="Da li ste sigurni da zelite da obrisete lokalitet?"
          setDeleteData={setDeleteLocation}
          confirmDeletingData={confirmDeletingLocation}
        />
      )}
      {(isLoading || isDeleting || isRefetchingLocations) && (
        <div className="invisibleWrapper">
          <p className="loadingText">Loading...</p>
        </div>
      )}
    </div>
  );
};

export default Locations;
