import { Link } from "react-router-dom"

const NotFoundPage = () => {
    return(
        <div>
            <h1>Not found page</h1>
            <p>Go back to the <Link to='/'>Home page</Link></p>
        </div>
    )
}

export default NotFoundPage