import React from "react";
// import PropTypes from "prop-types";

const ImprovisedButton = ({ toggleFunction, children, className }) => (
  <span
    className={className}
    onMouseDown={(event) => {
      event.preventDefault(); // stops focus loss
      toggleFunction(); 
    }}
  >
    {children}
  </span>
);

// ImprovisedButton.propTypes = {
//   toggleFunction: PropTypes.func.isRequired,
//   children: PropTypes.node.isRequired,
//   className: PropTypes.string,
// };

export default ImprovisedButton;
