import "./LoginPage.css";

import { useState } from "react";

import { auth } from "../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { login } from "../../features/userSlice";
import { useDispatch } from "react-redux";

//admin@meetserbia.com
//z+9@4PU.
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password
      );
      dispatch(login(res.user.refreshToken));
      navigate("admin");
      sessionStorage.setItem("user", res.user.refreshToken);
    } catch (error) {
      console.error(error.message);
    }
    setEmail("");
    setPassword("");
  };
  return (
    <div className="login-page">
      <h1>Login strana</h1>
      <form className="loginForm" onSubmit={handleLogin}>
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit">Log in</button>
      </form>
    </div>
  );
};

export default LoginPage;
