import { useMutation, useQuery } from "react-query"
import { db, storage } from "../config/firebase"
import {
  orderByChild,
  query,
  ref as dbRef,
  remove,
  get,
} from "firebase/database"
import { deleteObject, ref } from "firebase/storage"
import {
  deleteLocationFromIndexedDB,
  getLocationsFromIndexedDB,
  saveLocationsToIndexedDB,
} from "../utils/indexedDB"

export const fetchLocations = async () => {
  const locationsRef = dbRef(db, "locations")
  const locationsQuery = query(locationsRef, orderByChild("nameLat"))

  try {
    const cachedLocations = await getLocationsFromIndexedDB()
    if (cachedLocations?.length > 0) {
      return cachedLocations
    }

    const snapshot = await get(locationsQuery)
    if (snapshot.exists()) {
      const locationsData = Object.keys(snapshot.val()).map((key) => {
        return snapshot.val()[key]
      })
      await saveLocationsToIndexedDB(locationsData)
      return locationsData
    } else {
      return []
    }
  } catch (error) {
    console.error("Error fetching locations:", error)
    throw error
  }
}
const removeLocation = async ({
  deleteLocation,
  setDeleteLocation,
  setIsLoading,
  queryClient,
}) => {
  try {
    const locationRef = dbRef(db, `locations/${deleteLocation.id}`) //removing location from the base
    await remove(locationRef)

    if (deleteLocation.images && deleteLocation.images.length) {
      deleteLocation.images.forEach(async (image) => {
        const imageRef = ref(storage, image) //removing images from storage
        await deleteObject(imageRef)
      })
    }

    if (deleteLocation.video !== "novideo") {
      const videoRef = ref(storage, deleteLocation.video)
      await deleteObject(videoRef)
    }
    const previousLocations = queryClient.getQueryData("locations") || []
    const newLocations = previousLocations.filter(
      (location) => location.id !== deleteLocation.id
    )
    queryClient.setQueryData("locations", newLocations)
    deleteLocationFromIndexedDB(deleteLocation.id)
  } catch (error) {
    console.log(error)
  } finally {
    setDeleteLocation(null)
    setIsLoading(false)
  }
}
export const useFetchLocationsData = () => {
  const {
    data: locations,
    isLoading,
    isError,
  } = useQuery("locations", fetchLocations, {
    cacheTime: 3600000,
  })
  return { locations, isLoading, isError }
}
export const useRemoveLocation = () => {
  return useMutation(removeLocation)
}
