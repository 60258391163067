import { configureStore } from "@reduxjs/toolkit"
import userReducer from "../features/userSlice"
import locationReducer from "../features/locationSlice"

const store = configureStore({
  reducer: {
    user: userReducer,
    location: locationReducer,
  },
})

export default store
