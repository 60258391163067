const openDatabase = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("LocationsDatabase", 1)

    request.onupgradeneeded = (event) => {
      const db = event.target.result
      db.createObjectStore("locations", { keyPath: "id" })
    }

    request.onerror = (event) => {
      reject("Error opening IndexedDB:", event.target.errorCode)
    }

    request.onsuccess = (event) => {
      resolve(event.target.result)
    }
  })
}

export const saveLocationsToIndexedDB = async (locations) => {
  const db = await openDatabase()
  const transaction = db.transaction(["locations"], "readwrite")
  const store = transaction.objectStore("locations")

  return Promise.all(
    locations.map((location) => {
      return new Promise((resolve, reject) => {
        const request = store.put(location)
        request.onsuccess = resolve
        request.onerror = reject
      })
    })
  ).then(() => db.close())
}

export const getLocationsFromIndexedDB = async () => {
  const db = await openDatabase()
  const transaction = db.transaction(["locations"], "readonly")
  const store = transaction.objectStore("locations")

  return new Promise((resolve, reject) => {
    const request = store.getAll()
    request.onsuccess = () => {
      resolve(request.result)
      db.close()
    }
    request.onerror = () => {
      reject("Error fetching locations from IndexedDB")
      db.close()
    }
  })
}
export const clearLocationsInIndexedDB = async () => {
  const db = await openDatabase()
  const transaction = db.transaction(["locations"], "readwrite")
  const store = transaction.objectStore("locations")
  const request = store.clear()

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve()
    request.onerror = () => reject(request.error)
  })
}
export const addLocationToIndexedDB = async (location) => {
  const db = await openDatabase()
  const transaction = db.transaction(["locations"], "readwrite")
  const store = transaction.objectStore("locations")
  const request = store.add(location)

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve()
    request.onerror = () => reject(request.error)
  })
}
export const updateLocationInIndexedDB = async (location) => {
  const db = await openDatabase()
  const transaction = db.transaction(["locations"], "readwrite")
  const store = transaction.objectStore("locations")
  const request = store.put(location)

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve()
    request.onerror = () => reject(request.error)
  })
}
export const deleteLocationFromIndexedDB = async (locationId) => {
  const db = await openDatabase()
  const transaction = db.transaction(["locations"], "readwrite")
  const store = transaction.objectStore("locations")
  const request = store.delete(locationId)

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve()
    request.onerror = () => reject(request.error)
  })
}
