import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  location: null,
}

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload
    },
    removeLocation: (state) => {
      state.location = null
    },
  },
})

export const { setLocation, removeLocation } = locationSlice.actions
export default locationSlice.reducer
