export const returnValueFromArrays = (refArr, storageArr) => {
  //using only in update location for images for now!!!
  if (refArr.length === 0 && storageArr.length === 0) return null

  if (refArr.length > 0 && storageArr.length === 0) {
    return refArr.map((ref) => ref.fullPath)
  }
  if (refArr.length === 0 && storageArr.length > 0) {
    return storageArr.map((item) => item.path)
  }
  if (refArr.length > 0 && storageArr.length > 0) {
    return refArr
      .map((ref) => ref.fullPath)
      .concat(storageArr.map((item) => item.path))
  }
}
