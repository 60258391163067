import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom"
import "./App.css"

//pages
import LoginPage from "./pages/LoginPage/LoginPage"
import AdminPanel from "./pages/AdminPage/AdminPanel"
import NotFoundPage from "./pages/NotFound/NotFoundPage"

//hooks
import { useSelector } from "react-redux"
import { login } from "./features/userSlice"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import Locations from "./pages/LocationsPage/Locations"

function App() {
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const user = sessionStorage.getItem("user")

    if (user) {
      dispatch(login(user))
    }
  }, [dispatch])

  useEffect(() => {
    navigate(location.pathname)
  }, [navigate, location.pathname])

  return (
    <div className="App">
      <main>
        <Routes>
          <Route
            path="/"
            element={!user ? <LoginPage /> : <Navigate to="admin" />}
          />
          <Route
            path="/admin"
            element={user ? <AdminPanel /> : <Navigate to="/" />}
          />
          <Route
            path="/locations"
            element={user ? <Locations /> : <Navigate to="/" />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
    </div>
  )
}

export default App
